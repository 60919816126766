<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {minLength, numeric, required, url} from "vuelidate/lib/validators";
import Master from "@/apis/Master";
import Swal from "sweetalert2";
import Vue from "vue";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "HOME SLIDERS",
      items: [
        {
          text: "DASHBOARD",
          href:"/"
        },
        {
          text: "HOME SLIDERS",
        }
      ],
      checkbox:[],
      isCheckAll:false,
      sliderData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      selected:"true",
      fields: [
        { key: "checkbox", sortable: false, label: ""},
        { key: "image", sortable: false, label: "DESKTOP IMAGE",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "imageMobile", sortable: false, label: "MOBILE IMAGE",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "url", sortable: true, label: "SLIDER URL",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "title", sortable: true, label: "SLIDER TITLE",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "priority", sortable: true, label: "SLIDER POSITION",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "status", sortable: true, label: "STATUS",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "action", label: "ACTION", sortable: false,  thStyle: { color: "black", "font-size":"16px"}}
      ],
      sliders: {
        title: "",
        sliderUrl: "",
        image: "",
        imageMobile: "",
        priority: "",
        status: "1"
      },
      editSlider:{
        title: "",
        sliderUrl: "",
        image: "",
        imageMobile: "",
        priority: "",
        status: ""
      },
      submitted: false,
      editSubmitted: false,
      showmodal: false,
      editData: [],
      successMessage: "",
      errorMessage: "",
      imagePath:"",
      preview:"",
      previewMobile:"",
      editshowmodal: false
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.sliderData.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.sliderData.length;

    Master.sliderList({
      trash: 0
    }).then((res) => {
      console.log(res)
      this.sliderData = res.data.data;
    })
  },
  validations: {
    sliders: {
      title: { required, minLength: minLength(3) },
      sliderUrl:{ url },
      image:{ required },
      imageMobile:{ required },
      priority: { required, numeric },
      status: { required }
    },
    editSlider:{
      title: { required, minLength: minLength(3) },
      sliderUrl:{ url },
      priority: { required, numeric },
      status: { required }
    }
  },
  methods: {
    /**
     * Search the table data with search input
     */
    checkAll: function(){
      console.log(this.perPage)
      this.isCheckAll = !this.isCheckAll;
      this.checkbox = [];
      if(this.isCheckAll){ // Check all
        for (var key in this.sliderData) {
          console.log(key,this.sliderData[key].id)
          this.checkbox.push(this.sliderData[key].id);
        }
      }
    },

    async bulkActive(){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else {
        Master.sliderBulkActive({
          ids:this.checkbox,
          status: 1
        }).then((res) => {
          // this.successMessage = res.data.message;
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          this.checkbox = []
          this.isCheckAll = false
          Master.sliderList({
            trash: 0
          }).then((res) => {
            console.log(res)
            this.sliderData = res.data.data;
          })
        })
      }

    },
    async bulkInactive(){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else {
        Master.sliderBulkInActive({
          ids: this.checkbox,
          status: 0
        }).then((res) => {
          // this.successMessage = res.data.message;
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          this.checkbox = []
          this.isCheckAll = false
          Master.sliderList({
            trash: 0
          }).then((res) => {
            console.log(res)
            this.sliderData = res.data.data;
          })
        })
      }
    },

    async bulkDelete(){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else {
        if (confirm('Are you sure you want to delete this item?')) {
          Master.sliderBulkDelete({
            ids: this.checkbox,
            trash: 0
          }).then((res) => {
            // this.successMessage = res.data.message;
            Vue.swal({
              position: "center",
              icon: "success",
              title: "<p style='color: red'>"+res.data.message+"</p>",
              showConfirmButton: false,
              timer: 1500
            });
            this.checkbox = []
            this.isCheckAll = false
            Master.sliderList({
              trash: 0
            }).then((res) => {
              console.log(res)
              this.sliderData = res.data.data;
            })
          })
        }
      }
    },
    editModal(data){
      this.editshowmodal = true
      Master.sliderDetails({
        id:data
      }).then((res) => {
        this.editSlider.id = res.data.data.id
        this.editSlider.title = res.data.data.title
        this.editSlider.priority = res.data.data.priority
        this.editSlider.sliderUrl = res.data.data.url
        this.preview = res.data.data.image
        this.previewMobile = res.data.data.image_mobile
        this.editSlider.status = res.data.data.status
        console.log(res)
      })
    },

    sliderImageUpload(event){
      this.sliders.image = event.target.files[0]
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        }
        this.editSlider.image= input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
      console.log(event)
    },
    sliderMobileImageUpload(event){
      this.sliders.imageMobile = event.target.files[0]
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.previewMobile = e.target.result;
        }
        this.editSlider.imageMobile = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
      console.log(event)
    },

    async deleteSlider(data){
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          Master.sliderDelete(
              {
                trash: 0,
                id: data
              }).then(res => {
            console.log(res)
            Swal.fire("Deleted!", "Your data has been deleted.", "success");
            // this.successMessage = res.data.message;
            Master.sliderList({
              trash: 0
            }).then((res) => {
              console.log(res)
              this.sliderData = res.data.data;
            })
          })
        }
      });
    },

    async handleSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.sliders.$invalid) {
        return;
      } else {
        const fd = new FormData();
        const config = { headers: { "Content-Type": "multipart/form-data" } };
        fd.append("image",this.sliders.image);
        fd.append("imageMobile",this.sliders.imageMobile);
        fd.append('data',JSON.stringify({
          "title" : this.sliders.title,
          "url" : this.sliders.sliderUrl,
          "priority" : this.sliders.priority,
          "status" : this.sliders.status
            }
        ));
        Master.sliderAdd(fd,config).then((res) => {
          console.log(res)
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          // this.successMessage = res.data.message;

          Master.sliderList({
            trash: 0
          }).then((res) => {
            console.log(res)
            this.sliderData = res.data.data;
          })
          this.showmodal = false;
        }).catch((err) => {
          console.log(err)
          // this.errorMessage = err.response.data.error.name[0];
          this.$bvToast.toast(""+err.response.data.error.title[0]+"", {
            title: `Error Message`,
            variant: 'danger',
            solid: true
          });
          this.showmodal = false;
        })

      }
      this.submitted = false;
      this.sliders.status = 1;
      this.sliders.title = "";
      this.sliders.sliderUrl = "";
      this.sliders.priority = "";
      this.sliders.image = "";
      this.sliders.imageMobile = "";
      this.preview = "";
      this.previewMobile = "";
      this.editSlider.image = ""
      this.editSlider.imageMobile = ""
    },

    handleUpdate(){
      this.editSubmitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.editSlider.$invalid) {
        return;
      } else {
        const fd = new FormData();
        const config = { headers: { "Content-Type": "multipart/form-data" } };
        fd.append("image",this.editSlider.image);
        fd.append("imageMobile",this.editSlider.imageMobile);
        fd.append('data',JSON.stringify({
              "id" : this.editSlider.id,
              "title" : this.editSlider.title,
              "url" : this.editSlider.sliderUrl,
              "priority" : this.editSlider.priority,
              "status" : this.editSlider.status
            }
        ));
        Master.sliderUpdate(fd,config).then((res) => {
          console.log(res)
          // this.successMessage = res.data.message;
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          Master.sliderList({
            trash: 0
          }).then((res) => {
            console.log(res)
            this.sliderData = res.data.data;
          })
          this.editshowmodal = false;
        }).catch((err) => {
          console.log(err)
          // this.errorMessage = err.response.data.errors;
          this.$bvToast.toast(""+err.response.data.errors+"", {
            title: `Error Message`,
            variant: 'danger',
            solid: true
          });
          this.editshowmodal = false;
        })
      }
      this.editSubmitted = false;
      this.preview = ""
      this.previewMobile = ""
      this.editSlider.image = ""
      this.editSlider.imageMobile = ""
      this.sliders.image = ""
      this.sliders.imageMobile = ""
    },
    hideModal() {
      this.editSlider.image = ""
      this.editSlider.imageMobile = ""
      this.sliders.image = ""
      this.sliders.imageMobile = ""
      this.sliders.title = "";
      this.sliders.sliderUrl = "";
      this.sliders.priority = "";
      this.preview = "";
      this.previewMobile = "";
      this.imagePath = "";
      this.submitted = false;
      this.showmodal = false;
    },
    hideEditModal() {
      this.editSubmitted = false;
      this.editshowmodal = false;
      this.preview = "";
      this.previewMobile = "";
      this.editSlider.image = ""
      this.editSlider.imageMobile = ""
      this.sliders.image = ""
      this.sliders.imageMobile = ""
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<template>
  <Layout>

    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" style="text-transform:uppercase !important;">
            <div class="btn-toolbar">
              <a href="javascript:void(0);" class="btn btn-primary mb-2 mr-1"  @click="showmodal = true">
                <i class="mdi mdi-plus mr-2"></i> Add
              </a>
              <a href="javascript:void(0);" class="btn btn-outline-success mb-2 mr-1" @click="bulkActive">
                <i class="mdi mdi-check mr-2"></i> Active
              </a>
              <a href="javascript:void(0);" class="btn btn-outline-danger mb-2 mr-1" @click="bulkInactive">
                <i class="mdi mdi-cancel mr-2"></i> In-Active
              </a>
              <a href="javascript:void(0);" class="btn btn-danger mb-2 mr-1" @click="bulkDelete">
                <i class="mdi mdi-trash-can mr-2"></i> Delete
              </a>
            </div>
            <b-alert show dismissible variant="success" v-if="successMessage">
              <i class="mdi mdi-check-all mr-2"></i>{{successMessage}}
            </b-alert>
            <b-alert show dismissible variant="danger" v-if="errorMessage">
              <i class="mdi mdi-block-helper mr-2"></i>{{ errorMessage }}
            </b-alert>
            <b-tabs nav-class="nav-tabs-custom">
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <div class="table-responsive">
                <b-table
                    class="table-centered"
                    :items="sliderData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                    striped
                    sort-icon-left
                >
                  <template v-slot:head(checkbox)>
                    <div class="form-check mb-1">
                      <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="isCheckAll"
                          :id="`customercheck`"
                          @click='checkAll()'
                      />
                      <label class="form-check-label" :for="`customercheck`"></label>
                    </div>
                  </template>
                  <template  v-slot:cell(image) = "data">
                    <img
                        :src="data.item.image"
                        alt="slider-img"
                        title="slider-img"
                        class="avatar-md"
                    />
                  </template>



                  <template v-slot:cell(url)="row">
                    <p class="badge font-size-12" v-if="row.item.url">
                      {{row.item.url}}
                    </p>
                    <p class="badge font-size-12" v-else>
                      NA
                    </p>
                  </template>
                  <template v-slot:cell(priority)="row">
                    <p class="badge font-size-12">
                      {{row.item.priority}}
                    </p>
                  </template>
                  <template v-slot:cell(title)="row">
                    <p class="badge font-size-12">
                      {{row.item.title}}
                    </p>
                  </template>

                  <template  v-slot:cell(imageMobile) = "data">
                    <img
                        :src="data.item.image_mobile"
                        alt="slider-img"
                        title="slider-img"
                        class="avatar-md"
                    />
                  </template>
                  <template v-slot:cell(status)="row">
                    <div v-if="row.value == 1"
                         class="badge font-size-12 badge-soft-success"
                    >ACTIVE</div>
                    <div v-if="row.value == 0"
                         class="badge font-size-12 badge-soft-danger"
                    >IN-ACTIVE</div>
                  </template>
                  <template v-slot:cell(checkbox)="row">
                    <div class="form-check mb-3">
                      <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="checkbox"
                          :id="`customercheck${row.index}`"
                          :value="row.item.id"
                      />
                      <label class="form-check-label" :for="`customercheck${row.index}`">&nbsp;</label>
                    </div>
                  </template>
                  <template v-slot:cell(action) = "data">
                    <a
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        @click="editModal(data.item.id)"
                        v-b-tooltip.hover
                        title="Edit"
                    >
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a>
                    <a
                        href="javascript:void(0);"
                        class="text-danger"
                        @click="deleteSlider(data.item.id)"
                        v-b-tooltip.hover
                        title="Delete"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          aria-controls
                      >
                      </b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
              <!--              </b-tab>-->
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Add -->
    <b-modal
        id="modal-1"
        v-model="showmodal"
        title="ADD HOME SLIDER"
        title-class="text-dark font-18"
        hide-footer
        @close="hideModal"
    >

      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="name">SLIDER TITLE *</label>
              <input
                  id="name"
                  v-model="sliders.title"
                  type="text"
                  class="form-control"
                  placeholder="ENTER SLIDER TITLE"
                  :class="{ 'is-invalid': submitted && $v.sliders.title.$error }"
                  style="text-transform: uppercase !important;"
              />
              <div v-if="submitted && $v.sliders.title.$error" class="invalid-feedback">
                <span v-if="!$v.sliders.title.required">SLIDER TITLE IS REQUIRED</span>
                <span v-if="!$v.sliders.title.minLength">SLIDER TITLE SHOULD BE ATLEAST 3 CHARACTER</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="code">SLIDER POSITION *</label>
              <input
                  id="code"
                  v-model="sliders.priority"
                  type="text"
                  class="form-control"
                  placeholder="ENTER POSITION"
                  :class="{ 'is-invalid': submitted && $v.sliders.priority.$error }"
              />
              <div v-if="submitted && $v.sliders.priority.$error" class="invalid-feedback">
                <span v-if="!$v.sliders.priority.required">POSITION IS REQUIRED</span>
                <span v-if="!$v.sliders.priority.numeric">POSITION MUST BE NUMERIC</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="code">SLIDER URL </label>
              <input
                  id="code"
                  v-model="sliders.sliderUrl"
                  type="text"
                  class="form-control"
                  placeholder="ENTER URL"
                  :class="{ 'is-invalid': submitted && $v.sliders.sliderUrl.$error }"
              />
              <div v-if="submitted && $v.sliders.sliderUrl.$error" class="invalid-feedback">
                <span v-if="!$v.sliders.sliderUrl.url">SLIDER URL IS INVALID</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="position">STATUS</label>
              <select class="form-control"
                      id="position"
                      v-model="sliders.status"
                      :class="{ 'is-invalid': submitted && $v.sliders.status.$error }">
                <option value="1">ACTIVE</option>
                <option value="0">IN-ACTIVE</option>
              </select>
              <div
                  v-if="submitted && !$v.sliders.status.required"
                  class="invalid-feedback"
              >Status is required</div>
            </div>
          </div>
        </div>


        <div class="form-group">
          <label for="code">DESKTOP IMAGE * <span style="font-size: 12px">(1920 x 940)</span></label>
          <br/>
          <input
              id="code"
              type="file"
              @change="sliderImageUpload"
              :class="{ 'is-invalid': submitted && $v.sliders.image.$error }"
          />
          <div v-if="submitted && $v.sliders.image.$error" class="invalid-feedback">
            <span v-if="!$v.sliders.image.required">DESKTOP IMAGE IS REQUIRED</span>
          </div>
<!--          Image Preview-->
          <div class="border p-2 mt-3">
          <template v-if="preview">
            <img :src="preview" class="avatar-md"/>
          </template>
          </div>

        </div>

        <div class="form-group">
          <label for="code">MOBILE IMAGE * <span style="font-size: 12px">(1080x 1440)</span></label>
          <br/>
          <input
              id="code"
              type="file"
              @change="sliderMobileImageUpload"
              :class="{ 'is-invalid': submitted && $v.sliders.imageMobile.$error }"
          />
          <div v-if="submitted && $v.sliders.imageMobile.$error" class="invalid-feedback">
            <span v-if="!$v.sliders.imageMobile.required">MOBILE IMAGE IS REQUIRED</span>
          </div>
          <!--          Image Preview-->
          <div class="border p-2 mt-3">
            <template v-if="previewMobile">
              <img :src="previewMobile" class="avatar-md"/>
            </template>
          </div>

        </div>



        <div class="text-right">
          <button type="submit" class="btn btn-success">SAVE</button>
          <b-button class="ml-1" variant="danger" @click="hideModal">CANCEL</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->


    <!-- Modal Edit -->
    <b-modal
        id="modal-1"
        v-model="editshowmodal"
        title="EDIT HOME SLIDER"
        title-class="text-dark font-18"
        hide-footer
        @close="hideEditModal"
    >
      <form @submit.prevent="handleUpdate">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="name">SLIDER TITLE *</label>
              <input
                  id="name"
                  v-model="editSlider.title"
                  type="text"
                  class="form-control"
                  placeholder="ENTER SLIDER TITLE"
                  :class="{ 'is-invalid': editSubmitted && $v.editSlider.title.$error }"
                  style="text-transform: uppercase !important;"
              />
              <div v-if="editSubmitted && $v.editSlider.title.$error" class="invalid-feedback">
                <span v-if="!$v.editSlider.title.required">SLIDER TITLE IS REQUIRED</span>
                <span v-if="!$v.editSlider.title.minLength">SLIDER TITLE SHOULD BE ATLEAST 3 CHARACTER</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="code">SLIDER POSITION *</label>
              <input
                  id="code"
                  v-model="editSlider.priority"
                  type="text"
                  class="form-control"
                  placeholder="ENTER POSITION"
                  :class="{ 'is-invalid': editSubmitted && $v.editSlider.priority.$error }"
              />
              <div v-if="editSubmitted && $v.editSlider.priority.$error" class="invalid-feedback">
                <span v-if="!$v.editSlider.priority.required">POSITION IS REQUIRED</span>
                <span v-if="!$v.editSlider.priority.numeric">POSITION MUST BE NUMERIC</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="code">SLIDER URL </label>
              <input
                  id="code"
                  v-model="editSlider.sliderUrl"
                  type="text"
                  class="form-control"
                  placeholder="ENTER URL"
                  :class="{ 'is-invalid': editSubmitted && $v.editSlider.sliderUrl.$error }"
              />
              <div v-if="editSubmitted && $v.editSlider.sliderUrl.$error" class="invalid-feedback">
                <span v-if="!$v.editSlider.sliderUrl.url">SLIDER URL IS INVALID</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="position">STATUS</label>
              <select class="form-control"
                      id="position"
                      v-model="editSlider.status"
                      :class="{ 'is-invalid': editSubmitted && $v.editSlider.status.$error }">
                <option value="1">ACTIVE</option>
                <option value="0">IN-ACTIVE</option>
              </select>
              <div
                  v-if="editSubmitted && !$v.editSlider.status.required"
                  class="invalid-feedback"
              >Status is required</div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="code">DESKTOP IMAGE * <span style="font-size: 12px">(1920 x 940)</span></label>
          <br/>
          <input
              id="code"
              type="file"
              @change="sliderImageUpload"
          />
          <!--          Image Preview-->
          <div class="border p-2 mt-3">
            <template v-if="preview">
              <img :src="preview" class="avatar-md"/>
            </template>
          </div>

        </div>

        <div class="form-group">
          <label for="code">MOBILE IMAGE * <span style="font-size: 12px">(1080x 1440)</span></label>
          <br/>
          <input
              id="code"
              type="file"
              @change="sliderMobileImageUpload"
          />
          <!--          Image Preview-->
          <div class="border p-2 mt-3">
            <template v-if="previewMobile">
              <img :src="previewMobile" class="avatar-md"/>
            </template>
          </div>

        </div>

        <div class="text-right">
          <button type="submit" class="btn btn-success">UPDATE</button>
          <b-button class="ml-1" variant="danger" @click="hideEditModal">CANCEL</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>